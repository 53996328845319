@tailwind base;
@tailwind components;
@tailwind utilities;
.App{
 
}

 
.piic{
  background-image: url("./DAVIS.JPG");
  min-height: 100vh;
  background-size:cover;
  background-position: center;
  
}
